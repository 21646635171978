import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"


import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogBody } from "../components/bodyContentBlog"

class DiretorioDeArtistaContentfulTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulDiretorioDeArtista
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.description || post.excerpt}
        />
        
        <div style={{
            backgroundColor: `${
              post.backgroundColour
            }`, color: `${
              post.textColour
            }`}}>
          {post.image && (
              <div className="post-content-image">
                <Img
                  fluid={post.image.fluid}
                  alt={post.title}
                  style={{position: "relative", width: "100%", height: "100%"}}
                />
              </div>
            )}
          <article
            className={`post-content ${`with-image` || `no-image`}`}
          >
            <header className="post-content-header">
              <h1 className="post-content-title">{post.title}</h1>
              <div className="postDetails"><a style={{color: `${
              post.textColour
            }`}} href={`/${post.category}`}>{post.category}</a></div>
              <div className="postDetails">{post.publishedDate}</div>
              <div className="postDetails">Publicado por <a style={{color: `${
              post.textColour
            }`}} href={`/${post.author}`}>{post.author}</a></div>
            </header>
            

            {post.description && (
              <p class="post-content-excerpt">{post.description}</p>
            )}

            <BlogBody content={post.bodyContent}/>

            
            <footer className="post-content-footer">
              {/* There are two options for how we display the byline/author-info.
          If the post has more than one author, we load a specific template
          from includes/byline-multiple.hbs, otherwise, we just use the
          default byline. */}
            </footer>
          </article>
          </div>
      </Layout>
    )
  }
}

export default DiretorioDeArtistaContentfulTemplate

export const pageQuery = graphql`
  query contentfulDiretorioDeArtistaBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulDiretorioDeArtista( slug: { eq: $slug } ){
      title
      description
      backgroundColour
      publishedDate(formatString: "DD/MM/YY")
      textColour
      image{
        fluid(maxWidth: 2048, quality: 100){
          src
          }
      }
      bodyContent {
        raw
        references{
            __typename
           contentful_id
           title
           fluid(quality: 100){
               src
            }
        }
      }
    }
  }
`
